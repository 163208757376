import { render, staticRenderFns } from "./Talk.vue?vue&type=template&id=fab4be26&scoped=true"
import script from "./Talk.vue?vue&type=script&lang=js"
export * from "./Talk.vue?vue&type=script&lang=js"
import style0 from "./Talk.vue?vue&type=style&index=0&id=fab4be26&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fab4be26",
  null
  
)

export default component.exports