<template>
  <div class="main-w" v-if="data">
    <div class="main_content">
      <p
        style="
          font-size: 24px;
          color: rgba(4, 11, 41, 1);
          margin: 80px 0 30px 0;
          text-align: center;
        "
      >
        {{ (tabledata && tabledata.cardTitle) || "Hello NIOer" }}
      </p>
      <div class="talkCardList" v-if="talkShow == 0">
        <div
          v-for="(item, k) in data"
          :key="k"
          class="talkCard"
          :class="{ talkCardShadow: item.detailsUrl }"
          @click="goRoute(item)"
        >
          <img :src="item.cardImage" />
          <div class="cardText">
            <p class="cardTitle">{{ item.cardTitle }}</p>
            <p class="cardInfo" v-html="item.cardContent"></p>
          </div>
        </div>
      </div>
      <!-- 图片超过5个 -->
      <div v-if="talkShow == 1">
        <el-carousel
          :interval="5000"
          arrow="never"
          height="
			390px"
          indicator-position="outside"
        >
          <el-carousel-item v-for="(item, k) in data" :key="k">
            <div class="talkCardList">
              <div
                v-for="(i, k) in item"
                :key="k"
                class="talkCard"
                :class="{ talkCardShadow: i.detailsUrl }"
                @click="goRoute(i)"
              >
                <img :src="i.cardImage" />
                <div class="cardText">
                  <p class="cardTitle">{{ i.cardTitle }}</p>
                  <p class="cardInfo" v-html="i.cardContent"></p>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { helloNioerList } from "@/utils/config";
import { getDataTalk } from "@/api/commonApi";
export default {
  name: "Talk",
  props: {
    dataId: {
      type: String,
      default: null,
      require: true,
    },
    table: {
      type: String,
      default: "1",
      require: true,
    },
  },
  data() {
    return {
      data: null,
      talkShow: 0,
      tabledata: null,
    };
  },

  created() {
    if (this.table == "2") {
      this.getData();
    } else {
      this.getTalkData();
    }
  },
  methods: {
    // 方程式师兄师姐说
    getData() {
      getDataTalk().then(([res]) => {
        this.tabledata = res.data[0];
        this.data = res.data[0].tbCardListModelList;
        // this.data.splice(4)
        let newdata = [];
        let current = 0;
        this.data.forEach((item, index) => {
          if (item.id == this.dataId) {
            this.data.splice(index, 1);
          }
        });
        if (this.data && this.data.length > 3) {
          this.talkShow = 1;
          for (let i = 0; i <= this.data.length - 1; i++) {
            if (i % 3 !== 0 || i === 0) {
              if (!newdata[current]) {
                newdata.push([this.data[i]]);
              } else {
                newdata[current].push(this.data[i]);
              }
            } else {
              current++;
              newdata.push([this.data[i]]);
            }
          }
          this.data = [...newdata];
        }
      });
    },
    // 获取轮播图数据
    getTalkData() {
      this.data = helloNioerList;
      // this.data.splice(4)
      let newdata = [];
      let current = 0;
      this.data.forEach((item, index) => {
        if (item.id == this.dataId) {
          this.data.splice(index, 1);
        }
      });
      if (this.data && this.data.length > 4) {
        this.talkShow = 1;
        for (let i = 0; i <= this.data.length - 1; i++) {
          if (i % 4 !== 0 || i === 0) {
            if (!newdata[current]) {
              newdata.push([this.data[i]]);
            } else {
              newdata[current].push(this.data[i]);
            }
          } else {
            current++;
            newdata.push([this.data[i]]);
          }
        }
        this.data = [...newdata];
      }
    },
    // 查看详情跳转
    goRoute(link) {
      if (link.detailsUrl) {
        window.open(
          link.detailsUrl + "?id=" + link.id + "&table=" + this.table
        );
        // this.$router.push({
        //     path: link.detailsUrl,
        //     query: {
        //         id: link.id
        //     }
        // })
      } else {
        return;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.main-w {
  width: 100%;
  height: auto;
  background: #f8fdfd;
  .main_content {
    padding-top: 10px;
    padding-bottom: 10px;
    max-width: 1200px;
    margin: 0px auto;
  }
}
.talkCardList {
  display: flex;
  width: 100%;
  padding: 0 82px;
  margin-bottom: 50px;
  justify-content: center;
  .talkCard {
    justify-content: space-between;
    position: relative;
    height: 337px;
    width: 248px;
    border-radius: 4px;
    margin-right: 79px;
    background-color: #fff;
    box-shadow: 0px 10px 20px 1px rgba(174, 174, 174, 0.1);

    overflow: hidden;
  }
  .talkCardShadow {
    justify-content: space-between;
    position: relative;
    width: 248px;

    border-radius: 4px;

    margin-right: 26px;

    background-color: #fff;
    box-shadow: 0px 10px 20px 1px rgba(174, 174, 174, 0.1);

    overflow: hidden;
  }
  .talkCardShadow:hover {
    cursor: pointer;
    box-shadow: 0px 4px 30px 4px rgba(174, 174, 174, 0.25),
      0px 10px 20px rgba(174, 174, 174, 0.1);
  }
  .talkCard:last-child {
    margin-right: 0;
  }
  img {
    height: 240px;
    width: 100%;
    object-fit: cover;
  }
  .cardText {
    .cardTitle {
      font-size: 16px;
      margin: 8px 16px;
      max-width: 220px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: #040b29;
    }
    .cardInfo {
      margin: 8px 16px;
      font-size: 12px;
      max-width: 220px;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #41475e;
      white-space: nowrap;
    }
  }
}

/deep/ .el-carousel {
  .el-carousel__indicators {
    // 指示器
    left: unset;
    transform: unset;
    right: 2%;
  }
  .el-carousel__button {
    // 指示器按钮
    width: 10px;
    height: 10px;
    border: none;
    border-radius: 50%;
    background-color: #96d6d6;
  }
  .is-active .el-carousel__button {
    // 指示器激活按钮
    background: #00bebe;
  }
}
</style>
